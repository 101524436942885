<template>
  <el-dialog
    :title="title"
    :visible="visible"
    :close-on-click-modal="false"
    :width="editForm.dialogWidth"
    @close="close"
    append-to-body
  >
    <edit-form ref="editForm" :formConfig="editForm"></edit-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">保存</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { submit } from "@/api/base";

export default {
  name: "EditPaymentBank",
  data() {
    return {
      visible: false,
      title: "",
      editForm: {
        dialogWidth: "400px",
        isInline: false,
        labelWidth: "100px",
        url: "/api/base/payType/getBankInfo",
        saveUrl: "/api/base/payType/editBank",
        data: {
          PaymentBankID: 0,
          BankName: "",
          BAccountName: "",
          BankAccount: "",
        },
        items: [
          {
            type: "input",
            label: "账号ID",
            prop: "PaymentBankID",
            dataType: "number",
            isHidden: true,
          },
          {
            type: "input",
            label: "账号类型",
            prop: "BankName",
            dataType: "string",
            isHidden: false,
          },
          {
            type: "input",
            label: "账号名称",
            prop: "BAccountName",
            dataType: "string",
            isHidden: false,
          },
          {
            type: "input",
            label: "账号卡号",
            prop: "BankAccount",
            dataType: "string",
            isHidden: false,
          },
        ],
        rules: {
          BankName: [
            {
              required: true,
              message: "账号类型不可为空",
              trigger: "blur",
            },
          ],
          BAccountName: [
            {
              required: true,
              message: "账号名称不可为空",
              trigger: "blur",
            },
          ],
          BankAccount: [
            {
              required: true,
              message: "账号卡号不可为空",
              trigger: "blur",
            },
          ],
        },
      },
    };
  },
  methods: {
    init(title, paymentBankID) {
      this.title = title;
      this.$nextTick(() => {
        if (paymentBankID > 0) {
          this.fetchData(paymentBankID);
        }
        this.$refs.editForm.clearValidate();
      });
      this.visible = true;
    },
    async fetchData(paymentBankID) {
      if (this.editForm.url) {
        const { data } = await submit(this.editForm.url, {
          paymentBankID: paymentBankID,
        });
        this.editForm.data = { ...this.editForm.data, ...data };
      }
    },
    save() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          await submit(this.editForm.saveUrl, {
            ...this.editForm.data,
            ...this.param,
          });
          this.$baseMessage("保存成功", "success");
          this.$emit("handleQuery");
          this.close();
        } else {
          return false;
        }
      });
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
