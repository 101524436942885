<template>
  <el-dialog title="转账支付" :visible="visible" :close-on-click-modal="false" width="785px" @close="close">
    <div class="page-top">
      <button-group :buttons="buttonConfig" @handleClick="callMethod" style="margin-bottom: 5px;padding:3px 5px;">
      </button-group>
    </div>
    <div class="page-table">
      <grid-table ref="gridTable" :tableConfig="tableConfig" @selectionChange="handleSelectionChange"
        @editRow="handleShowDialog">
      </grid-table>
    </div>
    <div class="page-dialog">
      <edit-payment-bank ref="editPaymentBank" v-if="dialogVisible" @handleQuery="handleQuery"></edit-payment-bank>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { submit } from "@/api/base";
import EditPaymentBank from "./edit.vue";

export default {
  name: "PaymentBank",
  components: {
    EditPaymentBank,
  },
  data() {
    return {
      visible: false,
      buttonConfig: [
        {
          label: "新增",
          icon: "ri-add-circle-fill",
          color: "#3dc54e",
          method: "handleShowDialog",
          isNeedSelectRow: false,
        },
        {
          label: "修改",
          icon: "ri-edit-circle-fill",
          color: "#5487e6",
          method: "handleShowDialog",
          isNeedSelectRow: true,
        },
        {
          label: "删除",
          icon: "ri-delete-bin-5-fill",
          color: "#E74C3C",
          method: "handleDelete",
          isNeedSelectRow: true,
        },
        {
          label: "刷新",
          icon: "ri-refresh-fill",
          color: "#10cf10",
          method: "handleSearch",
          isNeedSelectRow: false,
        },
      ],
      tableConfig: {
        url: "/api/base/payType/getBankList",
        rowKey: "PaymentBankID",
        columns: [
          {
            type: "text",
            label: "账号ID",
            prop: "PaymentBankID",
            width: "10px",
            isHidden: true,
          },
          {
            type: "text",
            label: "账号类型",
            prop: "BankName",
            width: "150px",
          },
          {
            type: "text",
            label: "账号名称",
            prop: "BAccountName",
            width: "250px",
          },
          {
            type: "text",
            label: "账号卡号",
            prop: "BankAccount",
            width: "250px",
          },
        ],
      },
      selectRows: [],
      dialogVisible: false,
    };
  },
  methods: {
    init() {
      this.visible = true;
      this.$nextTick(() => {
        this.handleQuery();
      });
    },
    handleQuery() {
      this.$refs.gridTable.fetchData();
    },
    handleSelectionChange(selection) {
      this.selectRows = selection;
    },
    callMethod(button) {
      let methods = this.$options.methods;
      const _this = this;
      methods[button.method](_this, button);
    },
    handleSearch(_this, button) {
      _this.handleQuery();
    },
    handleShowDialog(_this, button) {
      if (
        button.isNeedSelectRow &&
        (!_this.selectRows || _this.selectRows.length == 0)
      ) {
        _this.$baseMessage("请选择数据", "error");
        return false;
      }
      if (button.isNeedSelectRow && _this.selectRows.length > 1) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      _this.dialogVisible = true;
      _this.$nextTick(() => {
        _this.$refs.editPaymentBank.init(
          button.label,
          _this.selectRows.length > 0 ? _this.selectRows[0].PaymentBankID : 0
        );
      });
    },
    handleDelete(_this, button) {
      if (!_this.selectRows || _this.selectRows.length == 0) {
        _this.$baseMessage(`请选择要${button.label}的数据`, "error");
        return false;
      }
      _this.$baseConfirm(`确定选中项要${button.label}吗？`, null, async () => {
        await submit("/api/base/payType/deleteBank", {
          PaymentBankIDs: _this.selectRows
            .map((row) => row[_this.tableConfig.rowKey])
            .join(),
        });
        _this.handleQuery();
      });
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
